// routes
import { useLocation } from "react-router-dom";
import CheckConnection from "./pages/CheckConnection";
import Router from "./routes";
// import "./App.css";
// theme
import ThemeProvider from "./theme";

// ----------------------------------------------------------------------

export default function App() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const lgpath = params.get("lgpath");

  if (lgpath) {
    localStorage.setItem("lgpath", lgpath || "defaultPath");
  }
  console.log(lgpath);

  return (
    // <CheckConnection>
    <ThemeProvider>
      <Router />
    </ThemeProvider>
    //  </CheckConnection>
  );
}
