import React, { useState, useEffect } from "react";
import {
  Button,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  TextField,
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Snackbar,
} from "@mui/material";
import { baseUrl, loginPath, redirectUrl } from "../../config";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import "./AddEventModal.css";
import ModalTotal from "./ModalTotal";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import MuiAlert from "@mui/material/Alert";

const DuplicateEventModal = ({ editBookInfo, bookingMaster, setEditModal }) => {
  const current = new Date();
  const date = `${current.getFullYear()}-${
    current.getMonth() + 1
  }-${current.getDate()}`;

  const [cookChecked, setCookChecked] = useState(false);
  const [bookingType, setBookingType] = useState(editBookInfo[0][0].typ);
  const [eventType, setEventType] = useState(editBookInfo[0][0].event_typ);
  // const [cookInfo, setCookInfo] = useState(editCookInfo);
  const facilityInfo = editBookInfo[1];
  const amenityInfo = editBookInfo[2];
  const foodInfo = editBookInfo[3];
  const [gender, setGender] = useState(editBookInfo[0][0].gender);
  // ----------starting Time State----------//
  const [startTime, setStartTime] = useState(
    date + " " + editBookInfo[0][0].start_time
  );
  // ----------End Time State----------//
  const [endTime, setEndTime] = useState(
    date + " " + editBookInfo[0][0].end_time
  );

  // ----------Date State----------//
  const [newDate, setNewDate] = useState(editBookInfo[0][0].event_date);
  const [endDate, setEndDate] = useState(editBookInfo[0][0].event_end_date);
  // ----------Facility States----------//
  const [facility, setFacility] = useState([]);
  // const [facilityCPrice, setFacilityCPrice] = useState();
  const [facilityQt, setFacilityQt] = useState();
  // ----------Amenities States----------//

  const [amenities, setAmenities] = useState([]);
  const [amenitiesyQt, setAmenitiesQt] = useState();
  // const [amenitiesCPrice, setAmenitiesCPrice] = useState();
  // ----------Food States----------//
  const [foodItems, setFoodItems] = useState([]);
  const [foodItemQt, setFoodItemQt] = useState();
  // const [foodItemsCPrice, setFoodItemsCPrice] = useState();
  const [getStaff, setGetStaff] = useState([]);
  // ----------Cook Info----------//
  const editCookInfo = JSON.parse(editBookInfo[0][0].cook_info);
  const [cookName, setCookname] = useState(
    editCookInfo !== null ? editCookInfo.cookName : null
  );
  const [cookLicense, setCookLicense] = useState(
    editCookInfo !== null ? editCookInfo.cookLicenseNo : null
  );

  const [cookNumber, setcookNumber] = useState(
    editCookInfo !== null ? editCookInfo.cookNumber : null
  );
  const [cookDate, setCookDate] = useState(
    editCookInfo !== null ? editCookInfo.cookLicenseExpiry : null
  );
  const [error, setError] = useState(0);
  const [staff, setStaff] = useState(editBookInfo[0][0].staff);
  // ----------Net total----------//
  const netTotal = editBookInfo[0][0].total;
  // const [isButton, setIsButton] = useState(false);
  const [subTotal, setSubTotal] = useState(0);
  const [discTotal, setDiscTotal] = useState(editBookInfo[0][0].discount);
  const [taxType, setTaxType] = useState(editBookInfo[0][0].tax_typ);
  const [tax, setTax] = useState(0);
  const [taxTotal, setTaxTotal] = useState(0);
  const invoice =
    bookingMaster[0][0].invoice === null ? 1 : bookingMaster[0][0].invoice;

  const [toDate, setToDate] = useState(dayjs(new Date()));
  const [quiteOpen, setQuiteOpen] = React.useState(false);

  // ----------Submit data----------//
  const [cookNameList, setcookNameList] = useState([]);
  const [editEventInfo, setEditEventInfo] = useState({
    cust_id: 0,
    invoice_no: invoice,
    customerName: editBookInfo[0][0].name,
    customerNumber: editBookInfo[0][0].contact1,
    customerNumberTwo: editBookInfo[0][0].contact2,
    customerTelephone: editBookInfo[0][0].contact3,
    customerEmail: editBookInfo[0][0].email,
    customerAddress: editBookInfo[0][0].address1,
    customerAddressTwo: editBookInfo[0][0].address2,
    customerPinCode: editBookInfo[0][0].pincode,
    NoOfGuest: editBookInfo[0][0].guest_count,
    brideName: editBookInfo[0][0].bride_add,
    groomName: editBookInfo[0][0].groom_add,
    cookName: cookName,
    cookNumber: cookNumber,
    date: null,
    end_date: null,
    toDate: null,
    gender: null,
    startTime: null,
    endTime: null,
    facility: null,
    amenities: null,
    foodItems: null,
    netTotal: 0,
    suggestion: editBookInfo[0][0].description,
    eventType: "",
    bookingType: null,
    cookLicenseNo: cookLicense,
    custAdhaar: null,
    staffName: null,
    // cookLicenseNo: null,
    discount: 0,
    taxPrec: 0,
    taxType: null,
    subTotal: 0,
    gstNo: editBookInfo[0][0].gst_no,
  });
  const [snackOpen, setSnackOpen] = React.useState(false);
  const [snack, setSnack] = useState({
    typ: "",
    message: "",
  });
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  const navigate = useNavigate();
  const handleDiscChange = (event) => {
    setDiscTotal(event.target.value);
  };
  const handleTaxType = (event) => {
    setTaxType(event.target.value);
  };
  useEffect(() => {
    async function getTax() {
      await axios
        .get(baseUrl + "/api/company", { withCredentials: true })
        .then((res) => {
          setTax(res.data[0].tax);
        })
        .catch((err) => {
          if (err.response.status === 403 || err.response.status === 401) {
            window.location.href = redirectUrl;
          }
        });
    }
    async function fetchData() {
      await axios
        .get(baseUrl + "/api/getVoucherEmployee", { withCredentials: true })
        .then((res) => {
          setGetStaff(res.data[1]);
        })
        .catch((err) => {
          if (err.response.status === 403 || err.response.status === 401) {
            window.location.href = redirectUrl;
          }
        });
    }
    async function getCook() {
      await axios
        .get(baseUrl + "/api/getCooksMaster", { withCredentials: true })
        .then((res) => {
          const cookNameData = res.data;

          setcookNameList(cookNameData);
        })
        .catch((err) => {
          if (err.response.status === 403 || err.response.status === 401) {
            window.location.href = redirectUrl;
          }
        });
    }
    getCook();
    fetchData();
    getTax();
  }, []);
  useEffect(() => {
    // Loop through each facility item and update its netAmt based on the taxType
    const updatedFacility = facility.map((item) => {
      const netAmt =
        taxType === "none" ? item.totalPrice : item.totalPrice + item.taxAmt;
      return {
        ...item,
        netAmt,
      };
    });

    // Update the facility state with the updatedFacility array
    setFacility(updatedFacility);
  }, [taxType, facility]);
  useEffect(() => {
    // Loop through each facility item and update its netAmt based on the taxType
    const updatedAmenity = amenities.map((item) => {
      const netAmt =
        taxType === "none" ? item.totalPrice : item.totalPrice + item.taxAmt;
      return {
        ...item,
        netAmt,
      };
    });

    // Update the facility state with the updatedFacility array
    setAmenities(updatedAmenity);
  }, [taxType, amenities]);
  useEffect(() => {
    // Loop through each facility item and update its netAmt based on the taxType
    const updatedFoodItem = foodItems.map((item) => {
      const netAmt =
        taxType === "none" ? item.totalPrice : item.totalPrice + item.taxAmt;
      return {
        ...item,
        netAmt,
      };
    });

    // Update the facility state with the updatedFacility array
    setFoodItems(updatedFoodItem);
  }, [taxType, foodItems]);

  // const [updated, setUpdated] = useState({});
  const handleGenderChange = (event) => {
    setGender(event.target.value);
  };
  const handleEventTypeChange = (event) => {
    setEventType(event.target.value);
  };
  // const handleSetCookInfo = (event) => {
  //   setCookname(event.target.value);
  // };
  const handleStaffChange = (event) => {
    setStaff(event.target.value);
  };

  const handleSetCookNumber = (event) => {
    setcookNumber(event.target.value);
  };
  const handleSetCookLicense = (event) => {
    setCookLicense(event.target.value);
  };

  const HandleCheck = async () => {
    setCookChecked(!cookChecked);
  };
  const handleStartTimeChange = (newStartTime) => {
    setStartTime(newStartTime);
  };
  const handleEndTimeChange = (newEndTime) => {
    setEndTime(newEndTime);
  };
  const handleBookingTypeChange = (event) => {
    setBookingType(event.target.value);
  };
  const handleDateChange = (newDate) => {
    setNewDate(newDate);
  };
  const handleDateEndChange = (newDate) => {
    setEndDate(newDate);
  };
  const handleToDateChange = (newDate) => {
    setToDate(newDate);
  };

  let cust_id = 0;
  if (bookingMaster[0][0].cust_id === null) {
    cust_id = 1000;
  } else {
    cust_id = bookingMaster[0][0].cust_id;
  }

  useEffect(() => {
    function fetchData() {
      if (editCookInfo !== null) {
        setCookChecked(true);
      } else {
        setCookChecked(false);
      }
    }

    async function pushEditData() {
      amenityInfo.map((data) =>
        amenities.push({
          name: data.content_name,
          price: data.content_price,
          qty: data.content_qty,
          totalPrice: data.item_total,
          tax: data.item_tax,
          taxAmt: data.tax_amount,
          netAmt: data.content_total,
          typ: data.content_typ,
        })
      );
      facilityInfo.map((data) =>
        facility.push({
          name: data.content_name,
          price: data.content_price,
          qty: data.content_qty,
          totalPrice: data.item_total,
          tax: data.item_tax,
          taxAmt: data.tax_amount,
          netAmt: data.content_total,
          typ: data.content_typ,
        })
      );
      await foodInfo.map((data) =>
        foodItems.push({
          name: data.content_name,
          price: data.content_price,
          qty: data.content_qty,
          totalPrice: data.item_total,
          tax: data.item_tax,
          taxAmt: data.tax_amount,
          netAmt: data.content_total,
          typ: data.content_typ,
        })
      );
    }

    fetchData();
    pushEditData();
  }, []);

  const handleFacilityAdd = (i, e, value) => {
    const newFormValues = [...facility];

    newFormValues[i].price = value.price;
    newFormValues[i].totalPrice = value.price;
    newFormValues[i].name = value.name;
    newFormValues[i].tax = value.tax;
    newFormValues[i].taxAmt = (value.tax * value.price) / 100;
    newFormValues[i].typ = "FACILITY";
    newFormValues[i].netAmt =
      taxType === "none"
        ? newFormValues[i].totalPrice
        : newFormValues[i].totalPrice + newFormValues[i].taxAmt;

    // setFacilityCPrice(value.price);
    setFacility(newFormValues);
  };
  // ----------Changing Quantity----------//
  const handleFacilityPriceAdd = (i, e, value) => {
    const newFormValues = [...facility];
    const newQty = (newFormValues[i].qty = parseInt(e.target.value));
    newFormValues[i].totalPrice = newQty * facility[i].price;
    newFormValues[i].taxAmt =
      (newFormValues[i].tax * newFormValues[i].totalPrice) / 100;
    newFormValues[i].netAmt =
      taxType === "none"
        ? newFormValues[i].totalPrice
        : newFormValues[i].totalPrice + newFormValues[i].taxAmt;

    setFacilityQt(newQty);
    setFacility(newFormValues);
  };

  // ----------Changing  Price ----------//
  const handleFacilityPriceChange = (i, e) => {
    const newFormValues = [...facility];
    const newPrice = (newFormValues[i].price = parseInt(e.target.value));
    newFormValues[i].totalPrice = newPrice * facility[i].qty;
    newFormValues[i].taxAmt =
      ((newFormValues[i].tax * newFormValues[i].totalPrice) / 100) * facilityQt;
    newFormValues[i].netAmt =
      newFormValues[i].totalPrice + newFormValues[i].taxAmt;
    setFacility(newFormValues);
  };
  let addFacilityForm = () => {
    setFacility([...facility, { price: 0, qty: 1, totalPrice: 0 }]);
  };

  let removeFacilityForm = (i) => {
    let newFormValues = [...facility];
    newFormValues.splice(i, 1);
    setFacility(newFormValues);
  };

  // ----------Amenitie Functions----------//
  const handleAmenitiesAdd = (i, e, value) => {
    const newFormValues = [...amenities];
    newFormValues[i].price = value.price;
    newFormValues[i].totalPrice = value.price;
    newFormValues[i].name = value.name;
    newFormValues[i].tax = value.tax;
    newFormValues[i].taxAmt = (value.tax * newFormValues[i].totalPrice) / 100;
    newFormValues[i].netAmt =
      taxType === "none"
        ? newFormValues[i].totalPrice
        : newFormValues[i].totalPrice + newFormValues[i].taxAmt;

    newFormValues[i].typ = "AMENITIES";
    // setAmenitiesCPrice(value.price);
    setAmenities(newFormValues);
  };
  // ----------Quantity Change ----------//
  const handleAmenitiesPriceAdd = (i, e, value) => {
    const newFormValues = [...amenities];
    const newQty = (newFormValues[i].qty = parseInt(e.target.value));
    newFormValues[i].totalPrice = newQty * amenities[i].price;
    newFormValues[i].taxAmt =
      (newFormValues[i].tax * newFormValues[i].totalPrice) / 100;
    newFormValues[i].netAmt =
      taxType === "none"
        ? newFormValues[i].totalPrice
        : newFormValues[i].totalPrice + newFormValues[i].taxAmt;

    setAmenitiesQt(newQty);
    setAmenities(newFormValues);
  };
  // ----------Changing Total Price ----------//
  const handleAmenitiesPriceChange = (i, e) => {
    const newFormValues = [...amenities];
    const newPrice = (newFormValues[i].price = parseInt(e.target.value));
    newFormValues[i].totalPrice = newPrice * amenities[i].qty;
    newFormValues[i].taxAmt =
      ((newFormValues[i].tax * newFormValues[i].totalPrice) / 100) *
      amenitiesyQt;
    newFormValues[i].totalPrice = newPrice * amenitiesyQt;
    newFormValues[i].netAmt =
      newFormValues[i].totalPrice + newFormValues[i].taxAmt;
    setAmenities(newFormValues);
  };
  let addAmenitiesForm = () => {
    setAmenities([...amenities, { price: 0, qty: 1, totalPrice: 0 }]);
  };
  let removeAmenitiesForm = (i) => {
    let newFormValues = [...amenities];
    newFormValues.splice(i, 1);
    setAmenities(newFormValues);
  };

  // ----------Food Items Functions----------//
  let handleFoodItemsAdd = (i, e, value) => {
    let newFormValues = [...foodItems];
    newFormValues[i].price = value.price;
    newFormValues[i].totalPrice = value.price;
    newFormValues[i].name = value.name;
    newFormValues[i].tax = value.tax;
    newFormValues[i].taxAmt = (value.tax * newFormValues[i].totalPrice) / 100;
    newFormValues[i].netAmt =
      taxType === "none"
        ? newFormValues[i].totalPrice
        : newFormValues[i].totalPrice + newFormValues[i].taxAmt;

    newFormValues[i].typ = "FOOD";
    // setFacilityCPrice(value.price);
    // setFacility(newFormValues);

    // setFoodItemsCPrice(value.price);
    setFoodItems(newFormValues);
  };

  // ----------Quantity Changing----------//
  const handleFoodItemsPriceAdd = (i, e, value) => {
    const newFormValues = [...foodItems];
    const newQty = (newFormValues[i].qty = parseInt(e.target.value));
    newFormValues[i].totalPrice = newQty * foodItems[i].price;
    newFormValues[i].taxAmt =
      (newFormValues[i].tax * newFormValues[i].totalPrice) / 100;
    newFormValues[i].netAmt =
      newFormValues[i].taxAmt + newFormValues[i].totalPrice;
    setFoodItemQt(newQty);
    setFoodItems(newFormValues);
  };
  // ----------Changing  Price ----------//
  const handleFoodPriceChange = (i, e) => {
    const newFormValues = [...foodItems];
    const newPrice = (newFormValues[i].price = parseInt(e.target.value));
    newFormValues[i].totalPrice = newPrice * foodItems[i].qty;
    newFormValues[i].taxAmt =
      ((newFormValues[i].tax * newFormValues[i].totalPrice) / 100) * foodItemQt;
    newFormValues[i].totalPrice = newPrice * foodItemQt;
    newFormValues[i].netAmt =
      newFormValues[i].totalPrice + newFormValues[i].taxAmt;
    setFoodItems(newFormValues);
  };
  let addFoodItemsForm = () => {
    setFoodItems([...foodItems, { price: 0, qty: 1, totalPrice: 0 }]);
  };
  let removeFoodItemsForm = (i) => {
    let newFormValues = [...foodItems];
    newFormValues.splice(i, 1);
    setFoodItems(newFormValues);
  };
  // ----------Calculating Total Amount----------//
  useEffect(() => {
    calcNetTotal();
    calctaxTotal();
  }, [facility, amenities, foodItems]);
  function calcNetTotal() {
    const subTotalElement = [...facility, ...amenities, ...foodItems];
    const subTotalValue = subTotalElement.reduce((acc, curr) => {
      acc += Number(curr?.totalPrice || 0);

      return acc;
    }, 0);
    setSubTotal(subTotalValue);
  }
  function calctaxTotal() {
    const taxAmotTotal = [...facility, ...amenities, ...foodItems];
    const taxAmtValue = taxAmotTotal.reduce((acc, curr) => {
      acc += Number(curr?.taxAmt || 0);

      return acc;
    }, 0);
    setTaxTotal(taxAmtValue);
  }

  const handleUpdateinfo = (e) => {
    const newFormData = { ...editEventInfo };

    const fieldName = e.target.getAttribute("name");
    const fieldValue = e.target.value;

    newFormData[fieldName] = fieldValue;

    setEditEventInfo(newFormData);
  };

  const updateForm = (e) => {
    e.preventDefault();

    let name = document.getElementById("name");
    let whatsapp = document.getElementById("whatsapp");
    name.style.background = "transparent";
    whatsapp.style.background = "transparent";
    //  const updteddate ={
    editEventInfo.cust_id = cust_id;
    editEventInfo.cookName = cookName;
    editEventInfo.cookNumber = onchangedcooknumber;
    editEventInfo.cookLicenseNo = onchangedcookLicenseNo;
    editEventInfo.date = newDate;
    editEventInfo.end_date = endDate;
    editEventInfo.toDate = toDate;
    editEventInfo.startTime = startTime;
    editEventInfo.endTime = endTime;
    editEventInfo.facility = facility;
    editEventInfo.amenities = amenities;
    editEventInfo.foodItems = foodItems;
    editEventInfo.netTotal =
      taxType !== "none"
        ? subTotal - discTotal + ((subTotal - discTotal) / 100) * tax
        : subTotal - discTotal;
    editEventInfo.eventType = eventType;
    editEventInfo.bookingType = bookingType;
    editEventInfo.custAdhaar = null;
    editEventInfo.gender = gender;
    editEventInfo.discount = discTotal;
    editEventInfo.taxType = taxType;
    editEventInfo.subTotal = subTotal;
    editEventInfo.taxPrec = tax;
    editEventInfo.staffName = staff;

    // }
    // setUpdated(editEventInfo);

    if (
      editEventInfo.customerName !== null &&
      editEventInfo.customerName !== " " &&
      editEventInfo.customerNumber.toString().length === 12 &&
      editEventInfo.customerNumber !== " "
    ) {
      axios({
        method: "post",
        url: baseUrl + "/api/addBooking",
        data: editEventInfo, // you are sending body instead
        withCredentials: true,
      })
        .then((res) => {
          if (res.data) {
            setQuiteOpen(true);
          }
        })
        .catch((err) => {
          if (err.response.status === 403 || err.response.status === 401) {
            window.location.href = redirectUrl;
          }
        });
    } else {
      if (
        editEventInfo.customerName === null ||
        editEventInfo.customerName === " "
      ) {
        name.style.background = "rgb(255 0 0 / 18%)";
        name.style.borderRadius = "6px";
      }
      if (
        editEventInfo.customerNumber.toString().length < 12 ||
        editEventInfo.customerNumber === " "
      ) {
        whatsapp.style.background = "rgb(255 0 0 / 18%)";
        whatsapp.style.borderRadius = "6px";
      }
    }
  };

  const handleModalClose = (e) => {
    setQuiteOpen(false);

    setEditModal(false);
  };
  const handlePassRow = () => {
    const datas = {
      cust_id: editEventInfo.cust_id,
      name: editEventInfo.customerName,
      netTotal: editEventInfo.netTotal,
      balance: editEventInfo.netTotal,
      date: editEventInfo.toDate,
      event_typ: editEventInfo.eventType,
      mobile: editEventInfo.customerNumber.slice(2),
    };
    routeChange(datas);
  };

  const handleSMS = () => {
    const smsMob = editEventInfo.customerNumber.slice(2);
    const smsName = editEventInfo.customerName;
    const bookDate = dayjs(editEventInfo.date).format("DD-MM-YYYY");
    if (bookingType === "ENQUIRY") {
      const smsUrl =
        "https://sapteleservices.com/SMS_API/sendsms.php?username=centuryauditorium&password=fe8cf8&mobile=" +
        smsMob +
        "&sendername=CNRYAD&message=Dear" +
        smsName +
        ",  thank you for showing interest in Century auditorium. For any queries contact 9562410044. Looking forward to hear from you soon. Regards Century auditorium&routetype=2";

      axios.get(smsUrl);
      setSnack({
        typ: "success",
        message: "sms send",
      });
      setSnackOpen(true);
    } else if (bookingType === "BOOKING") {
      const smsUrl =
        "https://sapteleservices.com/SMS_API/sendsms.php?username=centuryauditorium&password=fe8cf8&mobile=" +
        smsMob +
        "&sendername=CNRYAD&message=Dear " +
        smsName +
        ",  your booking for marriage is confirmed with Century auditorium for " +
        bookDate +
        ". For any queries contact 9562410044. Thanks and regards Century Auditorium, Mele chelari&routetype=2";
      axios.get(smsUrl);
      setSnack({
        typ: "success",
        message: "sms send",
      });
      setSnackOpen(true);
    }
  };

  const handleSnackClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackOpen(false);
  };

  const routeChange = (datas) => {
    let path = "/Paymentreceipt";
    const data = { state: datas };
    navigate(path, data);
  };

  const [onchangedcooknumber, setonchangedcooknumber] = useState(null);
  const [onchangedcookLicenseNo, setonchangedcookLicenseNo] = useState(null);

  const onChangedCookDetails = (e, v) => {
    axios({
      method: "post",
      url: baseUrl + "/api/getCooksDeatails",
      data: { name: v.label }, // you are sending body instead
      withCredentials: true,
    })
      .then(
        (res) => {
          // if (res.data === true) {
          //   // modalClose();
          //   setQuiteOpen(true);
          // setselectedCookName(v.label);
          setCookname(v.label);
          setCookDate(res.data[0].Date1);
          setCookLicense(res.data[0].TinNo);
          setcookNumber(res.data[0].Ph);
          setError(res.data[0].expired);
          // setonchangedcooknumber(res.data[0].Ph);
          // editEventInfo.cookNumber = onchangedcooknumber;
          // setonchangedcookLicenseNo(res.data[0].TinNo);
          // editEventInfo.cookLicenseNo = onchangedcookLicenseNo;
          // bookingDetails.cookNumber = res.data[0].Ph;
          // bookingDetails.cookLicense = res.data[0].TinNo;
        }

        // setBookingDetails.cookNumber="res.data.id"
        // }
      )
      .catch((err) => {
        if (err.response.status === 403 || err.response.status === 401) {
          window.location.href = redirectUrl;
        }
      });
  };
  return (
    <>
      <Snackbar
        style={{ bottom: "24px", left: "auto", right: "24px" }}
        open={snackOpen}
        autoHideDuration={2000}
        onClose={handleSnackClose}
        //key={vertical + horizontal}
      >
        <Alert
          onClose={handleSnackClose}
          severity={snack.typ}
          sx={{ width: "100%" }}
        >
          {snack.message}
        </Alert>
      </Snackbar>
      <Dialog
        open={quiteOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {/* <DialogTitle id="alert-dialog-title">
          {"  Advance payment?"}
        </DialogTitle> */}
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Advance payment?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="primary" type="submit">
            <Link
              to="/dashboard/Receipts"
              onClick={handleModalClose}
              style={{ textDecoration: "none", color: "blue" }}
            >
              Back
            </Link>
          </Button>
          <Button autoFocus variant="contained" onClick={handlePassRow}>
            Yes
          </Button>
          <Button
            color="warning"
            autoFocus
            variant="contained"
            onClick={handleSMS}
          >
            SMS
          </Button>
        </DialogActions>
      </Dialog>

      <form>
        <div className="modalBody">
          <h3>Duplicate Customer Information</h3>
          <Box className="customerInfo" autoComplete="off">
            <TextField
              className="textField"
              size="small"
              InputProps={{
                readOnly: true,
              }}
              label="Customer ID"
              type="number"
              name="customerId"
              variant="outlined"
              value={cust_id}
            />
            <TextField
              id="name"
              className="textField"
              size="small"
              type="text"
              label="Customer Name"
              value={editEventInfo.customerName}
              name="customerName"
              variant="outlined"
              onChange={handleUpdateinfo}
            />
            <FormControl sx={{ minWidth: 150 }} className="staffname">
              <InputLabel id="demo-simple-select-standard-label">
                Staff Name
              </InputLabel>
              <Select
                size="small"
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={staff}
                onChange={handleStaffChange}
                label="Transaction Mode"
                name="staffName"
              >
                {getStaff.map((getStaff) => (
                  <MenuItem value={getStaff.Name} key={getStaff.Name}>
                    {getStaff.Name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl sx={{ minWidth: 120 }}>
              <InputLabel id="demo-simple-select-label">Gender</InputLabel>
              <Select
                className="textField"
                size="small"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Gender"
                name="gender"
                value={gender}
                onChange={handleGenderChange}
              >
                <MenuItem value="Male">Male</MenuItem>
                <MenuItem value="Female">Female</MenuItem>
                <MenuItem value="Other">Other</MenuItem>
              </Select>
            </FormControl>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                className="textField"
                label="Booking Date"
                inputFormat="dddd, DD/MM/YYYY hh:mm:ss A"
                value={toDate}
                onChange={handleToDateChange}
                renderInput={(params) => (
                  <TextField {...params} size="small" margin="dense" />
                )}
              />
            </LocalizationProvider>
            <div className="adjusted_common_div">
              <TextField
                fullWidth
                id="whatsapp"
                className="textField"
                size="small"
                type="number"
                label="Whatsapp Number"
                name="customerNumber"
                variant="outlined"
                value={editEventInfo.customerNumber}
                onChange={handleUpdateinfo}
              />
            </div>
            <TextField
              className="textField"
              size="small"
              type="number"
              label="Contact Number 2"
              name="customerNumberTwo"
              variant="outlined"
              value={editEventInfo.customerNumberTwo}
              onChange={handleUpdateinfo}
            />
            <TextField
              className="textField"
              size="small"
              type="text"
              label="Telephone"
              name="customerTelephone"
              variant="outlined"
              value={editEventInfo.customerTelephone}
              onChange={handleUpdateinfo}
            />
            <TextField
              // className="textField"
              size="small"
              type="email"
              label="Email"
              name="customerEmail"
              variant="outlined"
              value={editEventInfo.customerEmail}
              onChange={handleUpdateinfo}
            />
            <TextField
              className="textField"
              size="small"
              type="text"
              label="Address"
              name="customerAddress"
              variant="outlined"
              value={editEventInfo.customerAddress}
              onChange={handleUpdateinfo}
            />
            <TextField
              className="textField"
              size="small"
              type="text"
              label="Address 2"
              name="customerAddressTwo"
              variant="outlined"
              value={editEventInfo.customerAddressTwo}
              onChange={handleUpdateinfo}
            />
            <TextField
              className="textField"
              size="small"
              type="text"
              label="GST Number"
              name="gstNo"
              variant="outlined"
              value={editEventInfo.gstNo}
              onChange={handleUpdateinfo}
            />
            <TextField
              className="textField"
              size="small"
              type="number"
              label="PinCode"
              name="customerPinCode"
              variant="outlined"
              value={editEventInfo.customerPinCode}
              onChange={handleUpdateinfo}
            />

            <FormControl sx={{ minWidth: 120 }}>
              <InputLabel id="demo-simple-select-label">
                Booking Type
              </InputLabel>
              <Select
                className="textField"
                size="small"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Booking Type"
                name="bookingType"
                value={bookingType}
                onChange={handleBookingTypeChange}
              >
                <MenuItem value="BOOKING">Booking</MenuItem>
                <MenuItem value="ENQUIRY">Enquiry</MenuItem>
              </Select>
            </FormControl>
          </Box>

          <br />
          <h3>Event Information</h3>
          <Box className="eventInfo" autoComplete="off">
            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
              <InputLabel id="demo-select-small">Event Type</InputLabel>
              <Select
                className="textField"
                labelId="demo-select-small"
                id="demo-select-small"
                label="Event Type"
                name="eventType"
                value={eventType}
                onChange={handleEventTypeChange}
              >
                {bookingMaster[1].map((options) => (
                  <MenuItem value={options.name}>{options.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                className="textField"
                label="Event Date"
                inputFormat="dddd DD/MM/YYYY"
                name="date"
                value={newDate}
                onChange={handleDateChange}
                renderInput={(params) => (
                  <TextField {...params} size="small" margin="dense" />
                )}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DesktopDatePicker
                className="textField"
                label="Event End Date"
                inputFormat="dddd DD/MM/YYYY"
                name="date"
                value={endDate}
                onChange={handleDateEndChange}
                renderInput={(params) => (
                  <TextField {...params} size="small" margin="dense" />
                )}
              />
            </LocalizationProvider>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                className="textField"
                label="Starting Time"
                // onChange={handleStartTimeChange}
                value={startTime}
                onChange={handleStartTimeChange}
                renderInput={(params) => (
                  <TextField size="small" margin="dense" {...params} />
                )}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                className="textField"
                label="Ending Time"
                value={endTime}
                onChange={handleEndTimeChange}
                renderInput={(params) => (
                  <TextField size="small" margin="dense" {...params} />
                )}
              />
            </LocalizationProvider>
            <div className="adjusted_common_div">
              <TextField
                fullWidth
                className="textField"
                size="small"
                type="number"
                label="No of guest"
                name="NoOfGuest"
                variant="outlined"
                value={editEventInfo.NoOfGuest}
                onChange={handleUpdateinfo}
              />
            </div>
            <TextField
              className="textField"
              size="small"
              type="text"
              multiline
              label="Bride Name And Address"
              name="brideName"
              variant="outlined"
              value={editEventInfo.brideName}
              onChange={handleUpdateinfo}
            />
            <TextField
              className="textField"
              size="small"
              type="text"
              multiline
              label="Groom Name And Address"
              name="groomName"
              variant="outlined"
              value={editEventInfo.groomName}
              onChange={handleUpdateinfo}
            />
            <FormGroup>
              <FormControlLabel
                className="textField"
                control={<Checkbox defaultChecked />}
                label="External Cook Information"
                onChange={HandleCheck}
                checked={cookChecked}
              />
            </FormGroup>
          </Box>
          <br />

          {cookChecked ? (
            <div className="cookInfo" autoComplete="off">
              <Autocomplete
                size="small"
                disablePortal
                id="combo-box-demo"
                options={cookNameList}
                // getOptionLabel={(cookNameList) => cookNameList.title}
                // defaultValue={"ss"}
                // sx={{ width: 300 }}
                value={editEventInfo.cookName}
                onChange={(e, v) => onChangedCookDetails(e, v)}
                renderInput={(params) => (
                  <TextField {...params} label="Cook Name" />
                )}
              />
              <TextField
                className="textField"
                size="small"
                type="text"
                label="Contact Number"
                name="cookNumber"
                variant="outlined"
                value={editEventInfo.cookNumber}
                onChange={handleSetCookNumber}
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                className="textField"
                size="small"
                type="text"
                label="License No"
                name="cookLicenseNo"
                variant="outlined"
                onChange={handleSetCookLicense}
                value={editEventInfo.cookLicenseNo}
                InputLabelProps={{ shrink: true }}
              />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  // className="textField"
                  label="Expiry Date"
                  inputFormat=" DD/MM/YYYY"
                  value={cookDate}
                  renderInput={(params) => (
                    <TextField
                      disableMaskedInput
                      {...params}
                      size="small"
                      margin="dense"
                      sx={{ m: 0 }}
                      error={error !== 0}
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
          ) : null}

          <br />
          <div className="button-section">
            <h2>Facilities</h2>
            <Button
              variant="contained"
              className="button_add"
              type="button"
              onClick={() => addFacilityForm()}
            >
              Add
            </Button>
          </div>
          <Box id="serviceSec" className="eventInfo" autoComplete="off">
            {facility.map((element, index) => (
              <div className="ServiceInpGrp" key={index}>
                <Autocomplete
                  className="textField"
                  data-id="@book.Id"
                  size="small"
                  disablePortal
                  id="combo-box-demo"
                  options={bookingMaster[2]}
                  defaultValue={element.name}
                  sx={{ width: 300 }}
                  onChange={(e, value) => handleFacilityAdd(index, e, value)}
                  value={facility.name}
                  name="name"
                  renderInput={(params) => (
                    <TextField {...params} label="Add Facility" />
                  )}
                />
                <TextField
                  label="Quantity"
                  size="small"
                  className="qtyInput textField"
                  type="number"
                  name="qty"
                  value={element.qty || ""}
                  onChange={(e) => handleFacilityPriceAdd(index, e)}
                />

                <TextField
                  label="Price"
                  size="small"
                  className="priceInput"
                  type="number"
                  name="price"
                  value={element.price || ""}
                  onChange={(e) => handleFacilityPriceChange(index, e)}
                />
                <TextField
                  label="Total"
                  size="small"
                  className="totalInput textField"
                  type="number"
                  name="totalPrice"
                  InputProps={{
                    readOnly: true,
                  }}
                  value={element.totalPrice || ""}
                  onChange={(e) => handleFacilityAdd(index, e)}
                />
                <TextField
                  label="Tax %"
                  size="small"
                  className="priceInput"
                  type="number"
                  name="tax_perc"
                  color="warning"
                  value={element.tax || 0}
                />

                <TextField
                  label="Tax Amount"
                  size="small"
                  className="priceInput"
                  type="number"
                  color="warning"
                  name="taxAmt"
                  value={element.taxAmt || 0}
                />
                <TextField
                  label="Net Amount"
                  size="small"
                  className="priceInput"
                  type="number"
                  name="netAmt"
                  // onChange={(e) => handleFacilityNetAmt(index, e)}
                  value={element.netAmt || 0}
                />

                <Button
                  variant="contained"
                  type="button"
                  className="button remove "
                  color="error"
                  size="small"
                  onClick={() => removeFacilityForm(index)}
                >
                  Remove
                </Button>
              </div>
            ))}
          </Box>
          <br />
          <br />
          <div className="button-section">
            <h2>Amenities</h2>
            <Button
              variant="contained"
              className="button_add"
              type="button"
              onClick={() => addAmenitiesForm()}
            >
              Add
            </Button>
          </div>
          <Box id="serviceSec" className="eventInfo" autoComplete="off">
            {/* <form  onSubmit={handleSubmit}> */}
            {amenities.map((element, index) => (
              <div className="ServiceInpGrp" key={index}>
                <Autocomplete
                  className="textField"
                  size="small"
                  data-id="@book.Id"
                  disablePortal
                  id="combo-box-demo"
                  options={bookingMaster[3]}
                  defaultValue={element.name}
                  name="name"
                  sx={{ width: 300 }}
                  onChange={(e, value) => handleAmenitiesAdd(index, e, value)}
                  value={amenities.name}
                  renderInput={(params) => (
                    <TextField {...params} label="Add Amenities" />
                  )}
                />
                <TextField
                  label="Quantity"
                  size="small"
                  className="qtyInput textField"
                  type="number"
                  name="qty"
                  value={element.qty || ""}
                  onChange={(e) => handleAmenitiesPriceAdd(index, e)}
                />
                <TextField
                  label="Price"
                  size="small"
                  className="priceInput textField"
                  type="number"
                  name="price"
                  value={element.price || ""}
                  onChange={(e) => handleAmenitiesPriceChange(index, e)}
                />
                <TextField
                  label="Total"
                  size="small"
                  className="totalInput textField"
                  InputProps={{
                    readOnly: true,
                  }}
                  type=""
                  name="totalPrice "
                  value={element.totalPrice || ""}
                  onChange={(e) => handleAmenitiesAdd(index, e)}
                />
                <TextField
                  label="Tax %"
                  size="small"
                  className="priceInput"
                  type="number"
                  name="tax_perc"
                  color="warning"
                  value={element.tax || 0}
                />

                <TextField
                  label="Tax Amount"
                  size="small"
                  className="priceInput"
                  type="number"
                  color="warning"
                  name="taxAmt"
                  value={element.taxAmt || 0}
                />
                <TextField
                  label="Net Amount"
                  size="small"
                  className="priceInput"
                  type="number"
                  name="netAmt"
                  // onChange={(e) => handleFacilityNetAmt(index, e)}
                  value={element.netAmt || 0}
                />

                <Button
                  variant="contained"
                  type="button"
                  className="button remove"
                  color="error"
                  size="small"
                  onClick={() => removeAmenitiesForm(index)}
                >
                  Remove
                </Button>
              </div>
            ))}
            {/* </form> */}
          </Box>
          <br />
          <br />

          <div className="button-section">
            <h2>Food Items</h2>
            <Button
              variant="contained"
              className="button_add"
              type="button"
              onClick={() => addFoodItemsForm()}
            >
              Add
            </Button>
          </div>
          <Box id="serviceSec" className="eventInfo" autoComplete="off">
            {/* <form  onSubmit={handleSubmit}> */}
            {foodItems.map((element, index) => (
              <div className="ServiceInpGrp" key={index}>
                <Autocomplete
                  className="textField"
                  size="small"
                  disablePortal
                  id="combo-box-demo"
                  options={bookingMaster[4]}
                  value={foodItems.name}
                  name="name"
                  defaultValue={element.name}
                  sx={{ width: 300 }}
                  onChange={(e, value) => handleFoodItemsAdd(index, e, value)}
                  renderInput={(params) => (
                    <TextField {...params} label="Add Food" />
                  )}
                />
                <TextField
                  label="Quantity"
                  size="small"
                  className="qtyInput textField"
                  type="number"
                  name="qty"
                  value={element.qty || ""}
                  onChange={(e) => handleFoodItemsPriceAdd(index, e)}
                />
                <TextField
                  label="Price"
                  size="small"
                  className="priceInput textField"
                  type="number"
                  name="price"
                  value={element.price || ""}
                  onChange={(e) => handleFoodPriceChange(index, e)}
                />
                <TextField
                  label="Total"
                  size="small"
                  className="totalInput textField"
                  readOnly
                  type=""
                  InputProps={{
                    readOnly: true,
                  }}
                  name="totalPrice"
                  value={element.totalPrice || ""}
                  onChange={(e) => handleFoodItemsAdd(index, e)}
                />
                <TextField
                  label="Tax %"
                  size="small"
                  className="priceInput"
                  type="number"
                  name="tax_perc"
                  color="warning"
                  value={element.tax || 0}
                />

                <TextField
                  label="Tax Amount"
                  size="small"
                  className="priceInput"
                  type="number"
                  color="warning"
                  name="taxAmt"
                  value={element.taxAmt || 0}
                />
                <TextField
                  label="Net Amount"
                  size="small"
                  className="priceInput"
                  type="number"
                  name="netAmt"
                  // onChange={(e) => handleFacilityNetAmt(index, e)}
                  value={element.netAmt || 0}
                />

                <Button
                  variant="contained"
                  type="button"
                  className="button remove"
                  color="error"
                  size="small"
                  onClick={() => removeFoodItemsForm(index)}
                >
                  Remove
                </Button>
              </div>
            ))}
            {/* </form> */}
          </Box>
          <br />
          <br />

          <TextField
            id="outlined-multiline-flexible"
            label="Remarks"
            multiline
            maxRows={6}
            name="suggestion"
            className="custSugg textField"
            value={editEventInfo.suggestion}
            onChange={handleUpdateinfo}
          />
          <div className="footerInputs">
            <TextField
              // className="textField"
              size="small"
              type="number"
              label="Discount"
              name="discount"
              variant="standard"
              value={discTotal}
              onChange={handleDiscChange}
            />

            <FormControl sx={{ width: 200 }}>
              <InputLabel id="demo-simple-select-label">Tax</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                value={taxType}
                label="Tax"
                onChange={handleTaxType}
                size="small"
                variant="standard"
              >
                <MenuItem value="none">none</MenuItem>
                <MenuItem value="CGST">CGST</MenuItem>
                <MenuItem value="IGST">IGST</MenuItem>
                <MenuItem value="VAT">VAT</MenuItem>
              </Select>
            </FormControl>
          </div>
          {/* {isButton==true?disabled: } */}
          <div className="sub-btn">
            <Button type="submit" onClick={updateForm} variant="contained">
              Save Event
            </Button>
            {/* {isButton !== true ? (
            <Button type="submit" onClick={updateForm} variant="contained">
              Save Event
            </Button>
            ) : (
              <Button type="submit" variant="contained" disabled>
                Update Event
              </Button>
            )} */}
          </div>
        </div>
      </form>
      <ModalTotal
        subTotal={Math.floor(subTotal)}
        tax={tax}
        taxType={taxType}
        discTotal={discTotal}
        netTotal={netTotal}
        taxTotal={taxTotal}
      />
      {/* <ModalTotal netTotal={netTotal} /> */}
    </>
  );
};

export default DuplicateEventModal;
