import React, { useEffect } from "react";
import $ from "jquery";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { baseUrl, loginPath, redirectUrl } from "../config";
import { useState } from "react";
import { TableContainer, Paper, Button, Card } from "@mui/material";
import NoAccess from "../NoAccess";

function LedgerTable() {
  const navigate = useNavigate();
  const pName = "customerLedger";
  const [access, setAccess] = useState(true);
  const [ledger, setLedger] = useState([]);
  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("acc"));

    if (items.includes(pName)) {
      setAccess(true);
      $(document).ready(function () {
        setTimeout(function () {
          $("#example").DataTable({
            pagingType: "full_numbers",
            pageLength: 10,
            processing: true,

            dom: "Bfrtip",
            buttons: [],
          });
        }, 1000);
      });
      async function getLadgerData() {
        await axios
          .get(baseUrl + "/api/getCustomersLedger", { withCredentials: true })
          .then((res) => {
            setLedger(res.data);
          })
          .catch((err) => {
            if (err.response.status === 403 || err.response.status === 401) {
              window.location.href = redirectUrl;
            }
          });
      }
      getLadgerData();
    } else {
      setAccess(false);
    }
  }, []);
  const handleGetId = (id, name, date) => {
    routeChange(id, name, date);
  };

  const routeChange = (cust_id, name, date1) => {
    let path = "/dashboard/ledgerData";

    const data = { state: { id: cust_id, name: name, date: date1 } };
    navigate(path, data);
  };

  return (
    <>
      {!access ? (
        <NoAccess />
      ) : (
        <>
          <h4>Customers Ledger</h4>
          <Card style={{ padding: "1rem" }}>
            <TableContainer component={Paper}>
              <table id="example" className=" hover">
                <thead className="tableHead">
                  <tr className="tableRow">
                    <th className="tableCell">SiNo</th>
                    <th className="th">Booking Date</th>
                    <th className="th">Booking ID</th>
                    <th className="th">Name</th>
                    <th className="th">Contact</th>
                    <th className="th"></th>
                  </tr>
                </thead>
                <tbody>
                  {ledger.map((ledger, index) => (
                    <tr className="tableRow" key={index}>
                      <td className="tableCell">{index + 1}</td>
                      <td className="td">{ledger.book_date}</td>
                      <td className="td">{ledger.cust_id}</td>
                      <td className="td">{ledger.name}</td>
                      <td className="td">{ledger.contact1}</td>
                      <td className="td">
                        {" "}
                        <Button
                          variant="contained"
                          size="small"
                          onClick={() =>
                            handleGetId(
                              ledger.cust_id,
                              ledger.name,
                              ledger.date1
                            )
                          }
                        >
                          Ledger
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </TableContainer>
          </Card>
        </>
      )}
    </>
  );
}

export default LedgerTable;
