import React, { useEffect, useState, Suspense, lazy } from "react";
import { Button, Card, IconButton, TextField, Tooltip } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { baseUrl, loginPath, redirectUrl } from "src/pages/config";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import NoAccess from "../NoAccess";
import SendIcon from "@mui/icons-material/Send";
import MuiAlert from "@mui/material/Alert";

// import "./Payment.css";
import axios from "axios";
import dayjs from "dayjs";
import Snackbar from "@mui/material/Snackbar";

import PaymentHistory from "./PaymentHistory";
//const PaymentHistory = lazy(() => import("./PaymentHistory"));
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Payment = () => {
  const pName = "receipt";
  const [access, setAccess] = useState(true);
  const [pendingPay, setPendingPay] = useState([]);
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [sDate, setsDate] = React.useState(dayjs());
  const [eDate, setEDate] = React.useState(dayjs());
  const [snack, setSnack] = useState({
    typ: "",
    message: "",
  });
  // const [fetchModal, setFetchModal] = useState();

  let navigate = useNavigate();
  const routeChange = (datas) => {
    let path = "/Paymentreceipt";
    const data = { state: datas };
    navigate(path, data);
  };
  const handlePassRow = (e) => {
    routeChange(e.row);
  };

  const handleSMS = (e) => {
    const smsMob = e.row.mobile.slice(2);
    const smsName = e.row.name;
    const smsBalance = e.row.balance;
    const smstype = e.row.event_typ;
    // const smsUrl = "http://sms.rbsindia.net/api/sms?format=xml&key=f0e290461b7636490d3c76bf10da86ff&method=MT&mobile=" + smsMob + "&sender=CNRYAD&route=TL&pe_id=1201167168496451916&pe_template_id=1207168128121452948&text=Dear " + smsName + ", " + smsBalance + " is due on your booking for " + smstype + ". Kindly pay the amount at the earliest. Thanks Century auditorium, Mele chelari";
    const smsUrl =
      "https://sapteleservices.com/SMS_API/sendsms.php?username=centuryauditorium&password=fe8cf8&mobile=" +
      smsMob +
      "&sendername=CNRYAD&message=Dear " +
      smsName +
      ",  " +
      smsBalance +
      " is due on your booking for " +
      smstype +
      ". Kindly pay the amount at the earliest. Thanks Century auditorium, Mele chelari";
    // "https://sapteleservices.com/SMS_API/sendsms.php?username=screlinfo&password=1234567890&mobile=9633431553&sendername=SCRELI&message=Dear%20SincA%20Subscriber%20%2C%20%20%20%20%20%20%20%0A%20%20%20%20%20%20%20123456%20is%20your%20%20OTP%20for%20SincA%20billing%20app%20registration.%20%20Thanking%20you%20%2C%20Team%20SCREL%20%20info%20private%20limited&routetype=1&tid=1607100000000192599";
    axios.get(smsUrl);

    setOpen(true);
    setSnack({
      typ: "success",
      message: "Message sent",
    });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleOk = (e) => {
    e.preventDefault();
    setConfirmLoading(true);
    setTimeout(() => {
      setOpen(false);
      setConfirmLoading(false);
    }, 2000);
  };

  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("acc"));

    if (items.includes(pName)) {
      setAccess(true);
      axios
        .get(baseUrl + "/api/getPendingPayments", { withCredentials: true })
        .then((res) => {
          setPendingPay(res.data);
        })
        .catch((err) => {
          if (err.response.status === 403 || err.response.status === 401) {
            window.location.href = redirectUrl;
          }
        });
    } else {
      setAccess(false);
    }
  }, []);

  let data = [];
  let id = 1;
  {
    pendingPay.map((pending) => {
      if (pending.balance > 0) {
        const datas = {
          id: id,
          cust_id: pending.cust_id,
          date: dayjs(pending.event_date).format("DD-MM-YYYY"),
          name: pending.name,
          netTotal: pending.total,
          balance: pending.balance,
          event_typ: pending.event_typ,
          mobile: pending.contact1,
        };
        data.push(datas);
        id++;
      }
    });
  }

  const onRowsSelectionHandler = (ids) => {
    console.log(ids);
    const selectedRowsData = ids.map((id) => data.find((row) => row.id === id));
  };

  const columns = [
    { field: "id", headerName: "SiNo", width: 60 },
    { field: "cust_id", headerName: "Booking ID", width: 100 },
    { field: "date", headerName: "Date", width: 130 },
    {
      field: "name",
      headerName: "Name",
      type: "text",
      width: 170,
    },
    {
      field: "event_typ",
      headerName: "Event type",
      sortable: false,
      width: 100,
    },
    {
      field: "netTotal",
      headerName: "Total",
      sortable: false,
      width: 140,
    },
    {
      field: "balance",
      headerName: "Balance",
      sortable: false,
      width: 140,
    },

    {
      field: "Action",
      headerName: "Action",
      sortable: false,
      width: 130,
      renderCell: (params) => {
        return (
          <>
            <Button
              size="small"
              variant="contained"
              color="success"
              onClick={() => handlePassRow(params)}
            >
              Pay Now{" "}
            </Button>
            <Tooltip title="send sms">
              <IconButton
                aria-label="sms"
                color="info"
                onClick={() => handleSMS(params)}
              >
                <SendIcon />
              </IconButton>
            </Tooltip>
          </>
        );
      },
    },
  ];
  const startDateChange = (newValue) => {
    setsDate(newValue);
  };
  const endDateChange = (newValue) => {
    setEDate(newValue);
  };
  const paymentsByDate = () => {
    const dates = { sDate: sDate, eDate: eDate };

    axios({
      method: "post",
      url: baseUrl + "/api/getPendingPaymentsByDate",
      data: dates,
      withCredentials: true,
    })
      .then((res) => {
        setPendingPay(res.data);
      })
      .catch((err) => {
        if (err.response.status === 403 || err.response.status === 401) {
          window.location.href = redirectUrl;
        }
      });
  };
  return (
    <>
      {!access ? (
        <NoAccess />
      ) : (
        <>
          <Snackbar
            open={open}
            autoHideDuration={2000}
            onClose={handleClose}
            style={{ bottom: "24px", left: "auto", right: "24px" }}
          >
            <Alert
              onClose={handleClose}
              severity={snack.typ}
              sx={{ width: "100%" }}
            >
              {snack.message}
            </Alert>
          </Snackbar>
          <h3>Pending Receipts</h3>
          <Card id="payTable">
            <p style={{ padding: "1rem" }}>Choose date</p>
            <div className="dateSel" style={{ padding: "1rem" }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileDatePicker
                  size="small"
                  label="Start Date"
                  inputFormat="DD/MM/YYYY"
                  value={sDate}
                  onChange={startDateChange}
                  renderInput={(params) => (
                    <TextField {...params} size="small" />
                  )}
                />
                <MobileDatePicker
                  label="End Date"
                  inputFormat="DD/MM/YYYY"
                  value={eDate}
                  onChange={endDateChange}
                  renderInput={(params) => (
                    <TextField {...params} size="small" />
                  )}
                />
                <Button variant="outlined" onClick={paymentsByDate}>
                  Search
                </Button>
              </LocalizationProvider>
            </div>
            <div style={{ height: "400px", width: "100%" }}>
              <DataGrid
                // checkboxSelection
                // onSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
                rows={data}
                columns={columns}
                // onRowClick={(e) => handlePassRow(e)}
              />
            </div>
            <p style={{ padding: "10px" }}>
              {" "}
              <b>
                Total Amount :{" "}
                {Math.round(data.reduce((a, v) => (a = a + v.netTotal), 0))}
              </b>
            </p>
            <p style={{ padding: "10px" }}>
              <b>
                Total Balance :{" "}
                {Math.round(data.reduce((a, v) => (a = a + v.balance), 0))}
              </b>
            </p>
          </Card>
          <br />
          <h3> Receipts History</h3>
          <Card>
            <PaymentHistory />
          </Card>
        </>
      )}
    </>
  );
};

export default Payment;
