import React, { useEffect, useState } from "react";
import axios from "axios";
import { baseUrl, loginPath, redirectUrl } from "src/pages/config";
import { useNavigate } from "react-router-dom";
import { TextField, Button, Autocomplete } from "@mui/material";
import AddBoxIcon from "@mui/icons-material/AddBox";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import IconButton from "@mui/material/IconButton";

function AddStaff({ staffBook, setStaffModal }) {
  const [staffs, setStaffs] = useState([]);
  const navigate = useNavigate();
  const [values, setValues] = useState([{ title: "" }]);

  useEffect(() => {
    async function getStaffs() {
      await axios({
        method: "post",
        url: baseUrl + "/api/getStaffsByBookID",
        data: { book_id: staffBook.cust_id },
        withCredentials: true,
      })
        .then((res) => {
          if (res.data[1].length > 0) {
            setValues(res.data[1]);
          }
          setStaffs(res.data[0]);
        })
        .catch((err) => {
          if (err.response.status === 403 || err.response.status === 401) {
            window.location.href = redirectUrl;
          }
        });
    }
    getStaffs();
  }, []);

  const submit = (e) => {
    e.preventDefault();

    axios({
      method: "post",
      url: baseUrl + "/api/addStaffsByBookID",
      data: { staffs: values, book_id: staffBook.cust_id },
      withCredentials: true,
    }).then((res) => {
      if (res.data) {
        setStaffModal(false);
      } else {
        console.log("Unsaved");
      }
    });
  };

  let handleChange = (i, e, value) => {
    let newFormValues = [...values];

    newFormValues[i].title = value;

    setValues(newFormValues);
  };

  let addFormFields = () => {
    setValues([...values, { title: "" }]);
  };

  let removeFormFields = (i) => {
    let newFormValues = [...values];
    newFormValues.splice(i, 1);
    setValues(newFormValues);
  };

  return (
    <div style={{ width: "100%", maxWidth: "700px", height: "100%" }}>
      <div style={{ color: "black", padding: "10px" }}>
        <h6>Bookig ID : {staffBook.cust_id}</h6>
        <h6>Name : {staffBook.name}</h6>
        <h6>Event type : {staffBook.event_typ}</h6>
        <h6>Event date : {staffBook.date}</h6>
      </div>
      <form>
        <div
          style={{ width: "100%", textAlign: "right", paddingBottom: " 10px" }}
        >
          {/* <Button
            variant="outlined"
            className="button add"
            type="button"
            onClick={() => addFormFields()}
          >
            Add
          </Button> */}
          <IconButton
            color="primary"
            aria-label="delete"
            component="label"
            onClick={() => addFormFields()}
          >
            <AddBoxIcon />
          </IconButton>
        </div>
        {values.map((element, index) => (
          <div className="addStaffs" key={index}>
            <Autocomplete
              className="stffInput"
              //  options={names}
              id="free-solo-demo"
              freeSolo
              size="small"
              onChange={(e, value) => handleChange(index, e, value)}
              options={staffs.map((option) => option.title)}
              value={element.title || ""}
              name="title"
              renderInput={(params) => (
                <TextField {...params} label="Search Staff" />
              )}
            />
            <div style={{ marginBottom: "15px" }}>
              <IconButton
                aria-label="delete"
                type="button"
                color="error"
                className="button remove"
                onClick={() => removeFormFields(index)}
              >
                <DeleteForeverIcon />
              </IconButton>
            </div>
          </div>
        ))}
      </form>
      <div
        style={{ width: "100%", textAlign: "right", paddingBottom: " 10px" }}
      >
        <Button variant="contained" onClick={submit}>
          Submit
        </Button>
      </div>
    </div>
  );
}

export default AddStaff;
