import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import {
  Navigate,
  Link as RouterLink,
  useLocation,
  useNavigate,
} from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
import { Box, Link, Drawer, Typography } from "@mui/material";
// mock
// import account from '../../_mock/account';
// hooks
import useResponsive from "../../hooks/useResponsive";
// components
// import Logo from '../../components/Log';
import Scrollbar from "../../components/Scrollbar";
import NavSection from "../../components/NavSection";
//
import navConfig from "./NavConfig";
import { baseUrl } from "src/pages/config";
import axios from "axios";

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

const AccountStyle = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const Navigate = useNavigate();
  const [companyData, setCompanyData] = useState();
  useEffect(() => {
    getCompanyDetails();
  }, []);
  const getCompanyDetails = () => {
    axios({
      method: "get",
      url: baseUrl + "/main/getCompanyDetails",
      withCredentials: true,
    })
      .then((res) => {
        const value = res.data[0];
        setCompanyData(value);
      })
      .catch((err) => {
        if (err.response.status === 403) {
          // Navigate(loginPath);
        }
      });
  };
  const { pathname } = useLocation();

  const isDesktop = useResponsive("up", "lg");

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        "& .simplebar-content": {
          height: 1,
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Box sx={{ mb: 0, mx: 0, py: 3, pr: 2, pl: 2 }} elevation={2}>
        <Link
          underline="none"
          component={RouterLink}
          to="/"
          // sx={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}
        >
          <AccountStyle
            sx={{
              boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
              background: "white",
            }}
          >
            {/* <img style={{ width: "3rem" }} src="/favicon/favicon.png" alt="" /> */}
            <img style={{ width: "3rem" }} src={companyData?.logo} alt="" />

            {/* <Avatar src='./' alt="photoURL" /> */}
            {/* <Box sx={{ ml: 2 }}>
              <Typography
                variant="subtitle2"
                sx={{ color: "text.primary", fontSize: "1.2rem" }}
              >
                CENTURY
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: "text.secondary", textAlign: "center" }}
              >
                Auditorium
              </Typography>
            </Box> */}
          </AccountStyle>
        </Link>
      </Box>

      <NavSection navConfig={navConfig} />

      <Box sx={{ flexGrow: 1 }} />
      <Box
        sx={{ mb: 0, mx: 0, py: 1 }}
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          borderRadius: "10px",
        }}
      >
        {/* <Typography variant="subtitle2" sx={{ fontSize: "1rem" }}>
          WIZZO TECH
        </Typography>
        <Typography
          variant="body2"
          sx={{ color: "text.secondary", textAlign: "center" }}
        > */}
        <img
          style={{ width: "4rem", opacity: "0.7" }}
          src="/favicon/WizzoLogo.png"
          alt="wizzo tech"
        />

        {/* </Typography> */}
      </Box>
    </Scrollbar>
  );

  return (
    <>
      <RootStyle>
        {!isDesktop && (
          <Drawer
            open={isOpenSidebar}
            onClose={onCloseSidebar}
            PaperProps={{
              sx: { width: DRAWER_WIDTH },
            }}
          >
            {renderContent}
          </Drawer>
        )}

        {isDesktop && (
          <Drawer
            open
            variant="persistent"
            PaperProps={{
              sx: {
                width: DRAWER_WIDTH,
                bgcolor: "background.default",
                borderRightStyle: "dashed",
              },
            }}
          >
            {renderContent}
          </Drawer>
        )}
      </RootStyle>
    </>
  );
}
