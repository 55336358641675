import React from "react";
import { Box, Stack } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";

function SmallSkelton() {
  return (
    <Box sx={{ width: "100%", padding: "4rem" }}>
      <Stack spacing={2}>
        <Skeleton variant="rectangular" width={"100%"} height={40} />
        <Skeleton variant="rectangular" width={"100%"} height={40} />
        <Skeleton animation={false} />
      </Stack>
    </Box>
  );
}

export default SmallSkelton;
