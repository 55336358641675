import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Card, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { baseUrl } from "src/pages/config";
import NoAccess from "../NoAccess";

const columns = [
  {
    field: "",
    headerName: "SiNo",
    width: 70,
    renderCell: (index) => index.api.getRowIndex(index.row.id) + 1,
  },
  { field: "cust_id", headerName: "Book ID", width: 140 },
  { field: "name", headerName: "Name", width: 160 },
  { field: "contact1", headerName: "Contact", width: 150 },
  {
    field: "date",
    headerName: "Event Date",
    type: "date",
    width: 140,
  },
  {
    field: "bride_add",
    headerName: "Bride",
    // type: "date",
    width: 120,
  },
  {
    field: "groom_add",
    headerName: "Groom",
    // type: "date",
    width: 120,
  },
  {
    field: "action",
    headerName: "Action",
    sortable: false,
    width: 160,
    renderCell: (params) => {
      return (
        <>
          <Button size="small" variant="contained" color="success">
            Certificate{" "}
          </Button>
        </>
      );
    },
  },
];

function MarriageTable() {
  const [rows, setRows] = useState([]);
  const pName = "marriageCrtificate";
  const [access, setAccess] = useState(true);
  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("acc"));

    if (items.includes(pName)) {
      setAccess(true);
      function getData() {
        axios
          .get(baseUrl + "/api/getMarriageData", { withCredentials: true })
          .then((res) => {
            setRows(res.data);
          });
      }
      getData();
    } else {
      setAccess(false);
    }
  }, []);
  let navigate = useNavigate();
  const routeChange = (datas) => {
    let path = "/generate";
    const data = { state: datas };
    navigate(path, data);
  };

  const handlePassData = (e) => {
    routeChange(e.row);
  };
  return (
    <>
      {!access ? (
        <NoAccess />
      ) : (
        <>
          <h3>Marriage Certificate</h3>
          <Card>
            <div style={{ height: 400, width: "100%" }}>
              <DataGrid
                rows={rows}
                columns={columns}
                onRowClick={(e) => handlePassData(e)}
              />
            </div>
          </Card>
        </>
      )}
    </>
  );
}

export default MarriageTable;
