import React, { useState, useEffect } from "react";
import { Card, Box, TextField, Button } from "@mui/material";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Rating from "@mui/material/Rating";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import SentimentSatisfiedIcon from "@mui/icons-material/SentimentSatisfied";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAltOutlined";
import SentimentVerySatisfiedIcon from "@mui/icons-material/SentimentVerySatisfied";
import axios from "axios";
import { baseUrl, loginPath, redirectUrl } from "./config";
import { useNavigate, useParams } from "react-router-dom";
import "./FeedBack.css";
// import Button from '@mui/material/Button';
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const StyledRating = styled(Rating)(({ theme }) => ({
  "& .MuiRating-iconEmpty .MuiSvgIcon-root": {
    color: theme.palette.action.disabled,
  },
}));

const customIcons = {
  1: {
    icon: <SentimentVeryDissatisfiedIcon color="error" />,
    label: "Very Dissatisfied",
  },
  2: {
    icon: <SentimentDissatisfiedIcon color="error" />,
    label: "Dissatisfied",
  },
  3: {
    icon: <SentimentSatisfiedIcon color="warning" />,
    label: "Neutral",
  },
  4: {
    icon: <SentimentSatisfiedAltIcon color="success" />,
    label: "Satisfied",
  },
  5: {
    icon: <SentimentVerySatisfiedIcon color="success" />,
    label: "Very Satisfied",
  },
};

function IconContainer(props) {
  const { value, ...other } = props;
  return <span {...other}>{customIcons[value].icon}</span>;
}

IconContainer.propTypes = {
  value: PropTypes.number.isRequired,
};
const labels = {
  0: "Very Bad",
  1: "Bad",
  2: "Ok",
  3: "Good",
  4: "Excellent",
  5: "Perfect",
};

function getLabelText(value) {
  return `${value} Star${value !== 1 ? "s" : ""}, ${labels[value]}`;
}

function FeedBack() {
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const [rating, setRating] = useState(null);
  const { id } = useParams();
  const [feedbackDetails, setfeedbackDetails] = useState({
    book_id: id,
    name: "",
    mobile: 0,
    feedback: null,
    rating: rating,
  });
  const [hover, setHover] = React.useState(-1);

  const handleFeedBackDetail = (event) => {
    const inputFieldValue = event.target.value;
    const inputFieldName = event.target.name;
    const NewInputValue = {
      ...feedbackDetails,
      [inputFieldName]: inputFieldValue,
    };
    setfeedbackDetails(NewInputValue);
  };
  const handleGetRating = (event) => {
    setRating(event.target.value);
  };

  function formSubmit() {
    feedbackDetails.rating = rating;
    axios({
      method: "post",
      url: baseUrl + "/api/addCustomerFeedback",
      data: feedbackDetails, // you are sending body instead
      withCredentials: true,
    })
      .then((res) => {
        if (res.data === true) {
          setOpen(true);
        }
      })
      .catch((err) => {
        if (err.response.status === 403 || err.response.status === 401) {
          window.location.href = redirectUrl;
        }
      });
  }

  useEffect(() => {
    getNameNumber();
  }, []);
  async function getNameNumber() {
    await axios({
      method: "post",
      url: baseUrl + "/api/getNameMobileByBookID",
      data: { book_id: id },
      withCredentials: true,
    })
      .then((res) => {
        setfeedbackDetails({
          book_id: id,
          name: res.data[0].name,
          mobile: res.data[0].contact1,
          feedback: null,
          rating: rating,
        });
      })
      .catch((err) => {
        if (err.response.status === 403 || err.response.status === 401) {
          window.location.href = redirectUrl;
        }
      });
  }

  return (
    <>
      <Snackbar open={open} autoHideDuration={6000}>
        <Alert severity="success" sx={{ width: "100%" }}>
          Thank you for your feedback..
        </Alert>
      </Snackbar>
      <div className="feedbackBody">
        <Card className="feedBackContent">
          <h3>Feedback</h3>
          <TextField
            id="name"
            required
            className="textField"
            size="small"
            type="text"
            label="Customer Name"
            name="name"
            variant="outlined"
            value={feedbackDetails.name}
            onChange={handleFeedBackDetail}
          />

          <TextField
            id="mobile"
            className="textField"
            size="small"
            type="number"
            label="Mobile Number"
            name="mobile"
            variant="outlined"
            value={feedbackDetails.mobile}
            onChange={handleFeedBackDetail}
          />
          <div style={{ display: "flex" }}>
            <StyledRating
              size="large"
              className="emotion"
              //name="highlight-selected-only"
              name="rating"
              //defaultValue={2}
              onChangeActive={(event, newHover) => {
                setHover(newHover);
              }}
              IconContainerComponent={IconContainer}
              getLabelText={(value) => customIcons[value].label}
              highlightSelectedOnly
              onChange={handleGetRating}
            />
            <div>
              {rating !== null && (
                <Box sx={{ ml: 2 }}>
                  {labels[hover !== -1 ? hover : rating]}
                </Box>
              )}
            </div>
          </div>

          <TextField
            className="textField"
            size="small"
            rows={7}
            onChange={handleFeedBackDetail}
            type="text"
            multiline
            label="FeedBack Description"
            name="feedback"
            value={feedbackDetails.feedback}
            variant="outlined"
          />

          <Button type="submit" onClick={formSubmit} variant="contained">
            Send FeedBack
          </Button>
        </Card>
      </div>
    </>
  );
}

export default FeedBack;
