import React from "react";
import CertficateGeneRate from "./CertficateGeneRate";
import ReactToPrint from "react-to-print";
import { Button } from "@mui/material";
// import "./Payment.css";

import { Link } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";

class ComponentToPrint extends React.Component {
  render() {
    return <CertficateGeneRate />;
  }
}

class pendiPrint extends React.Component {
  render() {
    return (
      <div>
        <div style={{ float: "right", padding: "1rem" }}>
          <Link to={-1}>
            <CloseIcon />
          </Link>
        </div>
        <ComponentToPrint ref={(el) => (this.componentRef = el)} />
        <div style={{ float: "right", padding: "2rem" }}>
          <ReactToPrint
            trigger={() => <Button variant="contained">Print</Button>}
            content={() => this.componentRef}
          />
        </div>
      </div>
    );
  }
}

export default pendiPrint;
