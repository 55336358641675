import React from "react";

const NoData = () => {
  return (
    <div id="noData_avail"
    >
      <img style={{width: "5rem",textAlign:"center" }} src="https://img.icons8.com/fluency/100/null/inbox.png" />
      <p style={{color: "grey"}}>No Data</p>
    </div>
  );
};

export default NoData;
