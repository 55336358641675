import { Card } from "@mui/material";
import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { baseUrl, loginPath, redirectUrl } from "src/pages/config";
import NoData from "src/pages/NoData";

function SelectedStaffs() {
  const { state } = useLocation();
  const [staffs, setStaffs] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    async function getStaffs() {
      axios({
        method: "post",
        url: baseUrl + "/api/getStaffsByBook",
        data: { book_id: state.cust_id },
        withCredentials: true,
      })
        .then((res) => {
          setStaffs(res.data);
        })
        .catch((err) => {
          if (err.response.status === 403 || err.response.status === 401) {
            window.location.href = redirectUrl;
          }
        });
    }
    getStaffs();
  }, [0]);

  return (
    <Card sx={{ p: 2, width: 500 }} style={{ background: "#dcade6" }}>
      {staffs.length == 0 ? (
        <NoData />
      ) : (
        <>
          <h5> Housekeepers</h5>

          <ul>
            {staffs.map((staff) => (
              <li>{staff.title}</li>
            ))}
          </ul>
        </>
      )}
    </Card>
  );
}

export default SelectedStaffs;
