import React, { useState, useEffect } from "react";
import { Box, Card } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { baseUrl, loginPath, redirectUrl } from "src/pages/config";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

function VessalReturnCust() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [data, setData] = useState([]);

  useEffect(() => {
    async function getDamage() {
      try {
        const response = await axios.post(
          baseUrl + "/api/getAmenityDamage",
          {
            book_id: state.cust_id,
          },
          { withCredentials: true }
        );
        setData(response.data);
      } catch (err) {
        if (err.response.status === 403 || err.response.status === 401) {
          window.location.href = redirectUrl;
        }
      }
    }
    getDamage();
  }, [navigate, state.cust_id]);

  const columns = [
    { field: "id", headerName: "SiNo", width: 100 },
    { field: "ItemName", headerName: "Amenity Name", width: 200 },
    { field: "Qty", headerName: "Missing Qty", width: 150 },
    { field: "Rate", headerName: "Price", width: 150 },
    { field: "Total", headerName: "Total", width: 150 },
  ];

  return (
    <>
      <h3>Customer Wise Table</h3>
      <Box>
        <Card sx={{ p: 1 }}>
          <div style={{ height: 400, width: "100%" }}>
            <DataGrid
              rows={data}
              columns={columns}
              components={{
                Toolbar: GridToolbar,
              }}
            />
          </div>
        </Card>
      </Box>
    </>
  );
}

export default VessalReturnCust;
