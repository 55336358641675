// export  const baseUrl="https://auditorium.wizzohost.in/server"

const storedLgpath = localStorage.getItem("lgpath");
export const loginPath = "ur_d";

// export const homeUrl= "http://localhost:5173/"

// export const baseUrl = "http://localhost:3008";

// export const redirectUrl = storedLgpath
//   ? `http://localhost:5173${storedLgpath}`
//   : "http://localhost:5173/";




export const homeUrl = "https://one.wizzo.in/"; //AWS EC2

export const baseUrl = "https://server.wizzo.in"; // AWS EC2

// AWS EC2
export const redirectUrl = storedLgpath
  ? `https://one.wizzo.in${storedLgpath}`
  : "https://one.wizzo.in";
