import React, { useState, useEffect } from "react";
import { TextField, Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { baseUrl, loginPath, redirectUrl } from "src/pages/config";
import { useLocation } from "react-router-dom";
import axios from "axios";
import dayjs from "dayjs";
import "./Payment.css";

function HistoryPrint() {
  const [recData, setRecData] = useState({});

  const { state } = useLocation();
  const [comInfo, setComInfo] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    async function fetchData() {
      await axios({
        method: "post",
        url: baseUrl + "/api/getPaymentDetail",
        data: { SiNo: state },
        withCredentials: true,
      })
        .then((res) => {
          setRecData(res.data[0]);
        })
        .catch((err) => {
          if (err.response.status === 403 || err.response.status === 401) {
            window.location.href = redirectUrl;
          }
        });
    }
    async function getCompany() {
      await axios
        .get(baseUrl + "/api/company", { withCredentials: true })
        .then((res) => {
          setComInfo(res.data[0]);
        });
    }
    getCompany();
    fetchData();
  }, []);
  return (
    <>
      <div className="container">
        <div id="printablediv">
          <div className="recHeader">
            <div className="logo">
              {" "}
              <img style={{ width: "7rem" }} src="/static/CompanyLogo.png" />
            </div>
            <div className="cInfo">
              <p className="companyInformation">{comInfo.Add1}</p>
              <p className="companyInformation">{comInfo.Place}</p>
              <p className="companyInformation">
                {comInfo.Ph}, {comInfo.Country}
              </p>
              <p className="companyInformation">{comInfo.Web}</p>
              <p className="companyInformation">{comInfo.EMail}</p>
              <p className="companyInformation">GSTIN : {comInfo.Add2}</p>
            </div>
          </div>
          <hr />
          <div style={{ width: "100%", textAlign: "center" }}>
            <h3>Receipt </h3>
          </div>
          <div className="headerPay">
            <TextField
              InputLabelProps={{ shrink: true }}
              className="headerInp"
              type="text"
              InputProps={{
                readOnly: true,
              }}
              variant="standard"
              name="voucherNo"
              label="Receipts No "
              value={recData.SiNO}
            ></TextField>

            <TextField
              style={{ width: "47%" }}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                readOnly: true,
              }}
              // className="headerInp"
              text="text"
              variant="standard"
              name="recdate"
              label="Date"
              value={dayjs(recData.Date1).format("	dddd, MMM D, YYYY")}
              // value={dayjs(date).format("	dddd, MMM D, YYYY h:mm A")}
            ></TextField>
          </div>
          <div className="paymentBody">
            <div className="doubleLine">
              <TextField
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                variant="standard"
                name="cust_id"
                label="Customer ID"
                value={recData.cust_id}
              ></TextField>

              <TextField
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                variant="standard"
                name="name"
                label="Customer Name"
                value={recData.name}
              ></TextField>
            </div>
            <div className="doubleLine">
              <TextField
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  readOnly: true,
                }}
                type="text"
                variant="standard"
                name="name"
                label="Transaction Mode"
                value={recData.trans_type}
              ></TextField>

              <TextField
                InputLabelProps={{ shrink: true }}
                type="number"
                variant="standard"
                name="payAmount"
                value={recData.Amount}
                label="Amount Paying"
              ></TextField>
            </div>

            <TextField
              InputLabelProps={{ shrink: true }}
              type="number"
              variant="standard"
              name="chequeNo"
              label="Cheque Number"
              value={recData.ChequeNo}
            ></TextField>

            <TextField
              InputLabelProps={{ shrink: true }}
              type="text"
              variant="standard"
              name="narration"
              label="Narration"
              value={recData.Narration}
            ></TextField>
          </div>
          <Divider style={{ paddingTop: "1rem" }} />
          <div className="paymentFooter">
            <TextField
              InputLabelProps={{ shrink: true }}
              type="text"
              variant="standard"
              name="Staff_Name"
              label="Staff Name"
              value={recData.Employee}
            ></TextField>

            <div className="amountSection">
              <h3>
                Total Amount : <span>{recData.total}</span>
              </h3>
              <h3>
                Current Balance : <span>{recData.total - recData.Amount}</span>
              </h3>
              <Divider className="payDivider" />
              {/* <h3>
          Balance : <span>5478547</span>
        </h3> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HistoryPrint;
