import React from "react";
import PendingReceipt from "./PendingReceipt";
import ReactToPrint from "react-to-print";
import { Button } from "@mui/material";
// import "./Payment.css";

import { Link } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";

class ComponentToPrint extends React.Component {
  render() {
    return <PendingReceipt />;
  }
}

class pendiPrint extends React.Component {
  render() {
    return (
      <div className="printInfo">
        <div className="actionsRec">
          <Link to="/dashboard/Receipts">
            <CloseIcon />
          </Link>
        </div>
        <ComponentToPrint ref={(el) => (this.componentRef = el)} />
        <div className="save-btn"></div>
        <div className=" printBtn">
          <ReactToPrint
            trigger={() => (
              <Button variant="contained" className="printBTN">
                Print
              </Button>
            )}
            content={() => this.componentRef}
          />
        </div>
      </div>
    );
  }
}

export default pendiPrint;
