import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import axios from "axios";
import { baseUrl, loginPath, redirectUrl } from "../config";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import $ from "jquery";
import { Button } from "@mui/material";
import { ToWords } from "to-words";

function LedgerPrint({ ledData, custInfo, startDate, endDate, netTotal }) {
  const toWords = new ToWords();
  const [comInfo, setComInfo] = useState([]);
  const navigate = useNavigate();

  let words = toWords.convert(
    ledData.length > 0
      ? ledData.reduce((a, v) => (a = a + v.net_total), 0) -
          ledData.reduce((a, v) => (a = a + v.amount), 0)
      : 0,
    { currency: true }
  );
  useEffect(() => {
    // $(document).ready(function () {
    //   setTimeout(function () {
    //     $("#example").DataTable({
    //       pagingType: "full_numbers",
    //       pageLength: 10,
    //       processing: true,

    //       dom: "Bfrtip",
    //       buttons: [],
    //     });
    //   }, 700);
    // });
    async function getCompany() {
      await axios
        .get(baseUrl + "/api/company", { withCredentials: true })
        .then((res) => {
          setComInfo(res.data[0]);
        })
        .catch((err) => {
          if (err.response.status === 403 || err.response.status === 401) {
            window.location.href = redirectUrl;
          }
        });
    }

    getCompany();
  }, [0]);

  let amtTotal = 0;
  let neTotal = 0;

  const Print = (e) => {
    let printContents = document.getElementById("dataPrint").innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    window.location.reload();
  };
  return (
    <>
      <div id="dataPrint">
        <div className="company">
          <div className="logoM" style={{ width: "100%", textAlign: "center" }}>
            {" "}
            <img
              style={{ width: "9rem", textAlign: "center" }}
              src="/static/CompanyLogo.png"
            />
            <p>{comInfo.Add1}</p>
            <p>{comInfo.Place}</p>
            <p>
              {comInfo.Ph}, {comInfo.Country}
            </p>
          </div>
          <hr />
        </div>
        <div>
          <p>Party : {custInfo.name}</p>
          <p>Booking ID : {custInfo.cust_id}</p>
          <p>Address : {custInfo.address1}</p>
          <p>
            Phone : {custInfo.contact1} , {custInfo.contact2}
          </p>
        </div>
        <hr />
        {/* <div>{startDate} to {endDate} </div> */}

        <div>
          <TableContainer component={Paper}>
            <Table
              sx={{ minWidth: 500 }}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow>
                  <TableCell width={20} align="center">
                    SiNo
                  </TableCell>
                  <TableCell align="center">Date</TableCell>
                  <TableCell align="center">Particular</TableCell>
                  <TableCell align="center">Debit</TableCell>
                  <TableCell align="center">Credit</TableCell>
                  <TableCell align="center">Running Balance</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {ledData.map(
                  (data, index) => (
                    (amtTotal += data.amount),
                    (neTotal += data.net_total),
                    (
                      <TableRow
                        //   key={row.name}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row" align="center">
                          {data.TransactionType == "ToSales"
                            ? custInfo.cust_id
                            : data.siNo}
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          {dayjs(data.Date1).format("DD/MM/YYYY")}
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          {/* {data.TransactionType} */}
                          {data.TransactionType === "ToSales"
                            ? data.TransactionType +
                              "  ( " +
                              custInfo.event_typ +
                              " )"
                            : data.TransactionType}
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          {data.TransactionType == "ToSales" ||
                          data.TransactionType == "Cash"
                            ? data.net_total
                            : 0}
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          {data.TransactionType == "Cash" ||
                          data.TransactionType == "ToSalesReturn"
                            ? data.amount
                            : 0}
                        </TableCell>
                        <TableCell component="th" scope="row" align="center">
                          {data.TransactionType == "ToSales"
                            ? data.net_total
                            : neTotal - amtTotal}
                        </TableCell>
                      </TableRow>
                    )
                  )
                )}
                <TableRow
                  //   key={row.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    align="center"
                  ></TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    align="center"
                  ></TableCell>
                  <TableCell component="th" scope="row" align="center">
                    <b>Total :</b>{" "}
                  </TableCell>
                  <TableCell component="th" scope="row" align="center">
                    <b>{ledData.reduce((a, v) => (a = a + v.net_total), 0)}</b>
                  </TableCell>
                  <TableCell component="th" scope="row" align="center">
                    {" "}
                    <b>{ledData.reduce((a, v) => (a = a + v.amount), 0)}</b>
                  </TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    align="center"
                  ></TableCell>
                </TableRow>
                <TableRow
                  //   key={row.name}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    align="center"
                  ></TableCell>
                  <TableCell
                    component="th"
                    scope="row"
                    align="center"
                  ></TableCell>
                  <TableCell component="th" scope="row" align="center">
                    <b style={{ fontSize: "1rem" }}>{words}</b>
                  </TableCell>
                  <TableCell component="th" scope="row" align="right">
                    <b style={{ fontSize: "1rem" }}>Balance</b>
                  </TableCell>
                  <TableCell component="th" scope="row" align="left">
                    {" "}
                    <b style={{ fontSize: "1rem" }}>
                      {ledData.length > 0
                        ? ledData.reduce((a, v) => (a = a + v.net_total), 0) -
                          ledData.reduce((a, v) => (a = a + v.amount), 0)
                        : 0}
                    </b>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
      <div>
        {" "}
        <Button type="button" variant="contained" onClick={Print}>
          {" "}
          Print
        </Button>
      </div>
    </>
  );
}

export default LedgerPrint;
