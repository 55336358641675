import React, { Suspense, lazy, useEffect, useState } from "react";
import { Box, Paper } from "@mui/material";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Skelton from "../Skelton.js";
import NoAccess from "../NoAccess.js";

const VenueMatster = lazy(() => import("./components/VenueMatster.js"));
const FacilitiesMaster = lazy(() => import("./components/FacilitiesMaster.js"));
const EventMaster = lazy(() => import("./components/EventMaster.js"));
const FoodMaster = lazy(() => import("./components/FoodMaster.js"));

export default function LabTabs() {
  const pName = "master";
  const [access, setAccess] = useState(true);
  const [value, setValue] = React.useState("venue");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    const items = JSON.parse(localStorage.getItem("acc"));

    if (items.includes(pName)) {
      setAccess(true);
    } else {
      setAccess(false);
    }
  }, []);
  return (
    <div>
      {!access ? (
        <NoAccess />
      ) : (
        <Box
          sx={{ width: "100%", typography: "body1" }}
          component={Paper}
          elevation={5}
        >
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
                variant="scrollable"
                scrollButtons="auto"
                allowScrollButtonsMobile
              >
                <Tab label="Venue Master" value="venue" />
                <Tab label="Event Master" value="event" />
                <Tab label="Facilities master" value="facility" />
                <Tab label="Food master" value="food" />
                FacilitySample
              </TabList>
            </Box>
            <TabPanel value="venue">
              <Suspense fallback={<Skelton />}>
                <VenueMatster />
              </Suspense>
            </TabPanel>
            <TabPanel value="event">
              <Suspense fallback={<Skelton />}>
                <EventMaster />
              </Suspense>
            </TabPanel>
            <TabPanel value="facility">
              <Suspense fallback={<Skelton />}>
                <FacilitiesMaster />
              </Suspense>
            </TabPanel>
            <TabPanel value="food">
              <Suspense fallback={<Skelton />}>
                <FoodMaster />
              </Suspense>
            </TabPanel>
          </TabContext>
        </Box>
      )}
    </div>
  );
}

